<template>
  <div class="stage-list" :style="{ opacity: !$_loading_state ? 1 : 0 }">
    <div class="stage-list-header">ステージを確認してみよう</div>
    <MileStageMap :stage="activatedStageKey" @focus:stage="onFocusStage" />
    <FloatingBottomArea :stage="activatedStageKey" />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onBeforeUnmount,
  watch
} from '@vue/composition-api';
import { SystemDialogMessage, MileStageType } from '@/constants/enums';
import { lowerCase } from 'lodash';

export default defineComponent({
  components: {
    MileStageMap: () => import('@/components/mileStageList/MileStageMap.vue'),
    FloatingBottomArea: () =>
      import('@/components/mileStageList/FloatingBottomArea.vue')
  },

  setup: (props, context) => {
    const customer = ref(null);
    const activatedStageKey = ref(null);
    const stageId = ref(null);
    const stagePositionX = ref(0);

    const stageListElement = computed(
      () => document.querySelector('.stage-list') || null
    );

    const getCustomer = async () => {
      try {
        const { data } = await context.root
          .$repositories('customer')
          .getCustomer();
        customer.value = data;
        const stageKey = MileStageType.getMileStageTypeKeyById(
          customer.value?.mileage_detail.rank_id
        );
        stageId.value = lowerCase(MileStageType.getTypeName(stageKey));
        activatedStageKey.value = stageKey;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    const getStageX = async (stageId) => {
      // eslint-disable-next-line no-console
      console.debug(
        'document.querySelector(`#${stageId} :>> ',
        document.querySelector(`#${stageId}`)
      );
      const ICON_WIDTH = 50;
      const mapWidth = await stageListElement.value.clientWidth;
      const xPosition =
        (await document.querySelector(`#${stageId}`)?.getBBox().x) || 0;
      return xPosition + ICON_WIDTH * 0.5 - mapWidth * 0.5;
    };

    const scroll = (x) => {
      stageListElement.value.scrollLeft = x;
    };

    const onFocusStage = (stageKey) => {
      activatedStageKey.value = stageKey;
    };

    const init = async () => {
      await getCustomer();
    };

    const setPosition = async (stageId) => {
      stagePositionX.value = await getStageX(stageId);

      /* eslint-disable no-console */
      console.debug('stageId :>> ', stageId);
      console.debug('stagePositionX.value :>> ', stagePositionX.value);
      /* eslint-enable no-console */
      await scroll(stagePositionX.value);
      await context.root.$_loading_stop();
      if (stageListElement.value.scrollLeft <= 0) scroll(stagePositionX.value);
      // eslint-disable-next-line no-console
      console.debug('scrollLeft :>> ', stageListElement.value.scrollLeft);
    };

    // 初期表示時に該当する国の位置へスクロール
    watch(
      () => stageId.value,
      async (stageId, prevValue) => {
        if (prevValue === null) {
          const setIntervalId = setInterval(() => {
            // 要素が描画されるまで待機
            if (document.querySelector('.stage-list-map svg')) {
              clearInterval(setIntervalId);
              setPosition(stageId);
            }
          }, 800);
        }
      }
    );

    context.root.$_loading_start();
    window.addEventListener('load', init());

    onBeforeUnmount(() => {
      window.removeEventListener('load', init());
    });

    return {
      activatedStageKey,
      onFocusStage
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.stage-list {
  overflow: auto;
  min-height: 100vh;
}

.stage-list-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: variables.$white02;
  padding: calc(env(safe-area-inset-top) + 1.5rem) 0 3rem;
  background: linear-gradient(
    180deg,
    rgba(40, 40, 45, 0.4) -8%,
    rgba(40, 40, 45, 0) 100%
  );
}
</style>
