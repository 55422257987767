var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stage-list",
      style: { opacity: !_vm.$_loading_state ? 1 : 0 },
    },
    [
      _c("div", { staticClass: "stage-list-header" }, [
        _vm._v("ステージを確認してみよう"),
      ]),
      _c("MileStageMap", {
        attrs: { stage: _vm.activatedStageKey },
        on: { "focus:stage": _vm.onFocusStage },
      }),
      _c("FloatingBottomArea", { attrs: { stage: _vm.activatedStageKey } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }